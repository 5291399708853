<header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="/admin">Ассистент Йоко</a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Поиск" aria-label="Поиск"> -->
    <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
            <a class="nav-link" href="/login" id="signout" (click)="signout($event)">Выйти</a>
        </li>
    </ul>
</header>

<div class="container-fluid">
	<div class="row">
	    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
	        <div class="position-sticky pt-3">
	            <ul class="nav flex-column">
	                <li class="nav-item">
	                    <a class="nav-link" href="/admin/logic/games">
	                        <span data-feather="file"></span>
	                        Игры
	                    </a>
	                </li>
	                <li class="nav-item">
	                    <a class="nav-link" aria-current="page" href="/admin/logic/problems">
	                        <span data-feather="home"></span>
	                        Задания
	                    </a>
	                </li>
	                <li class="nav-item">
	                    <a class="nav-link" href="/admin/logic/rounds">
	                        <span data-feather="shopping-cart"></span>
	                        Раунды
	                    </a>
	                </li>
	            </ul>
	        </div>
	    </nav>

	    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
	        <router-outlet></router-outlet>
	    </main>
	</div>
</div>
