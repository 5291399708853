import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logic-game-streaming-problem',
  templateUrl: './logic-game-streaming-problem.component.html',
  styleUrls: ['./logic-game-streaming-problem.component.css']
})
export class LogicGameStreamingProblemComponent implements OnInit {
  @Input() problem: any = undefined;
  @Input() header: any = undefined;

  viewType: any = undefined;

  fontSize: any = 80;

  _INDEX_CHARS_MAP = ["А", "Б", "В", "Г", "Д"];
  
  constructor() { }

  ngOnInit(): void {
    return this.onChanges();
  }

  ngOnChanges(): void {
    return this.onChanges(); 
  }

  onChanges(): void {
    if (this.problem.description && this.problem.description.length < 20 && this.problem.images.length < 2) {
      this.viewType = "shortText";
    } else if (this.problem.images.length >= 2) {
      this.viewType = "images";
    } else if (this.problem.need_default_music_sign) {
      this.viewType = "musicSign";
    } else {
      this.viewType = "common";
    }

    console.log(this.viewType);
  }

  _trackBy(index: number) {
    return index;
  }

}
