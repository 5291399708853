import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './components/user/login/login.component';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgOptimizedImage } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AdminComponent } from './components/admin/admin.component';
import { AdminLogicComponent } from './components/admin/logic/logic.component';

import { AdminLogicProblemsComponent } from './components/admin/logic/admin-logic-problems/admin-logic-problems.component';
import { AdminLogicProblemViewComponent } from './components/admin/logic/admin-logic-problem-view/admin-logic-problem-view.component';
import { AdminLogicProblemEditComponent } from './components/admin/logic/admin-logic-problem-edit/admin-logic-problem-edit.component';
import { AdminLogicGamesComponent } from './components/admin/logic/admin-logic-games/admin-logic-games.component';
import { AdminLogicGameViewComponent } from './components/admin/logic/admin-logic-game-view/admin-logic-game-view.component';
import { AdminLogicGameEditComponent } from './components/admin/logic/admin-logic-game-edit/admin-logic-game-edit.component';
import { AdminLogicGameRoundViewComponent } from './components/admin/logic/admin-logic-game-round-view/admin-logic-game-round-view.component';
import { AdminLogicRoundsComponent } from './components/admin/logic/admin-logic-rounds/admin-logic-rounds.component';
import { AdminLogicRoundEditComponent } from './components/admin/logic/admin-logic-round-edit/admin-logic-round-edit.component';
import { AdminLogicGameProcessComponent } from './components/admin/logic/admin-logic-game-process/admin-logic-game-process.component';
import { AdminLogicGameAnswersComponent } from './components/admin/logic/admin-logic-game-answers/admin-logic-game-answers.component';
import { AdminLogicProblemEditMediaComponent } from './components/admin/logic/admin-logic-problem-edit-media/admin-logic-problem-edit-media.component';

import { LogicComponent } from './components/logic/logic.component';
import { LogicGameTeamComponent } from './components/logic/logic-game-team/logic-game-team.component';
import { LogicImagesBlockComponent } from './components/logic/common/logic-images-block/logic-images-block.component';
import { LogicGameStreamingComponent } from './components/logic/logic-game-streaming/logic-game-streaming.component';
import { LogicMillionaireProblemComponent } from './components/logic/problem-types/logic-millionaire-problem/logic-millionaire-problem.component';
import { LogicMusicProblemComponent } from './components/logic/problem-types/logic-music-problem/logic-music-problem.component';
import { LogicWwwProblemComponent } from './components/logic/problem-types/logic-www-problem/logic-www-problem.component';
import { LogicSelfGameProblemComponent } from './components/logic/problem-types/logic-self-game-problem/logic-self-game-problem.component';
import { LogicIntuitionProblemComponent } from './components/logic/problem-types/logic-intuition-problem/logic-intuition-problem.component';
import { LogicFieldOfMiraclesProblemComponent } from './components/logic/problem-types/logic-field-of-miracles-problem/logic-field-of-miracles-problem.component';
import { LogicHundredToOneProblemComponent } from './components/logic/problem-types/logic-hundred-to-one-problem/logic-hundred-to-one-problem.component';
import { LogicLogicProblemComponent } from './components/logic/problem-types/logic-logic-problem/logic-logic-problem.component';

import { BgCircleComponent } from './components/logic/common/bg-circle/bg-circle.component';
import { GameHeaderComponent } from './components/logic/common/game-header/game-header.component';
import { GameResultsComponent } from './components/logic/game-results/game-results.component';
import { YokoRedesignOnlyComponent } from './components/logic/common/yoko-redesign-only/yoko-redesign-only.component';

import { PlayComponent } from './components/poker/play/play.component';

import { AdminSantaGameComponent } from './components/admin/santa/admin-santa-game/admin-santa-game.component';
import { AdminSantaGamesComponent } from './components/admin/santa/admin-santa-games/admin-santa-games.component';
import { VariantComponent } from './components/logic/common/variant/variant.component';
import { AnswerComponent } from './components/logic/common/answer/answer.component';
import { ScaledTextComponent } from './components/logic/common/scaled-text/scaled-text.component';
import { RotatedCardComponent } from './components/logic/common/rotated-card/rotated-card.component';
import { AdminUsersIndexComponent } from './components/admin/users/admin-users-index/admin-users-index.component';
import { AdminUsersEditComponent } from './components/admin/users/admin-users-edit/admin-users-edit.component';
import { AdminGameStatisticsGamesComponent } from './components/admin/game-statistics/admin-game-statistics-games/admin-game-statistics-games.component';
import { AdminGameStatisticsPlayersComponent } from './components/admin/game-statistics/admin-game-statistics-players/admin-game-statistics-players.component';
import { AdminGameStatisticsPlayersEditComponent } from './components/admin/game-statistics/admin-game-statistics-players-edit/admin-game-statistics-players-edit.component';
import { LogicGameStreamingProblemComponent } from './components/logic/logic-game-streaming-problem/logic-game-streaming-problem.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LogicProblemViewComponent } from './components/logic/logic-problem-view/logic-problem-view.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminLogicProblemsComponent,
    AdminLogicProblemViewComponent,
    AdminLogicProblemEditComponent,
    AdminLogicGamesComponent,
    AdminLogicGameViewComponent,
    AdminLogicGameEditComponent,
    AdminLogicGameRoundViewComponent,
    AdminLogicRoundsComponent,
    AdminLogicRoundEditComponent,
    AdminLogicGameProcessComponent,
    LoginComponent,
    LogicComponent,
    LogicImagesBlockComponent,
    PlayComponent,
    LogicGameTeamComponent,
    AdminLogicGameAnswersComponent,
    LogicGameStreamingComponent,
    GameHeaderComponent,
    GameResultsComponent,
    AdminLogicProblemEditMediaComponent,
    LogicMillionaireProblemComponent,
    BgCircleComponent,
    YokoRedesignOnlyComponent,
    LogicMusicProblemComponent,
    LogicWwwProblemComponent,
    LogicSelfGameProblemComponent,
    LogicIntuitionProblemComponent,
    LogicFieldOfMiraclesProblemComponent,
    LogicHundredToOneProblemComponent,
    LogicLogicProblemComponent,
    AdminSantaGameComponent,
    AdminSantaGamesComponent,
    VariantComponent,
    AnswerComponent,
    ScaledTextComponent,
    RotatedCardComponent,
    AdminUsersIndexComponent,
    AdminUsersEditComponent,
    AdminGameStatisticsGamesComponent,
    AdminGameStatisticsPlayersComponent,
    AdminGameStatisticsPlayersEditComponent,
    LogicGameStreamingProblemComponent,
    AdminLogicComponent,
    NotFoundComponent,
    LogicProblemViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
