import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { LogicService } from 'src/app/services/logic.service';
import { CommonService } from 'src/app/services/common.service';

import { Problem } from 'src/app/models/logic/problem.model';
import { ProblemStep } from 'src/app/models/logic/problem.model';

@Component({
  selector: 'app-logic-problem-view',
  templateUrl: './logic-problem-view.component.html',
  styleUrls: ['./logic-problem-view.component.css']
})
export class LogicProblemViewComponent implements OnInit {
  header: any = undefined;
  problem: any = undefined;
  
  constructor(private commonService: CommonService, private logicService: LogicService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getProblem(this.route.snapshot.params["id"]);
  }

  getProblem(id: any): void {
    this.logicService.getProblemView(id).subscribe({
      next: (data) => {
        console.log(data);
        this.problem = new Problem(data["problem"]);
        this.header = data["header"];
      },
      error: (e) => this.commonService.processError(e)
    });
  }

}
