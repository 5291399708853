<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">Задания</h1>

    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            <select class="select-outline-secondary" id="round_type" name="round_type" [(ngModel)]="round_id" (change)="filterRound()">
                <option *ngFor="let round of rounds" value="{{round.id}}">{{round.name}}</option>
            </select>
        </div>

        <div class="btn-group mr-2">
            <a href="/admin/logic/problems/new">
                <button type="button" class="btn btn-sm btn-outline-secondary">Новое задание</button>
            </a>
        </div>
    </div>
</div>

<nav aria-label="...">
    <ul class="pagination">
        <li class="page-item">
            <a class="page-link" (click)="changePage($event, pagination?.first_page)" href="#">Начало</a>
        </li>
        <li *ngFor="let page of pagination?.pages" class="{{page == pagination.page ? 'page-item active' : 'page-item'}}">
            <a class="page-link" (click)="changePage($event, page)" href="#">{{page}}</a>
        </li>
        <li class="page-item">
            <a class="page-link" (click)="changePage($event, pagination?.last_page)" href="#">Конец</a>
        </li>
    </ul>
</nav>

<table class="table">
    <thead>
        <tr>
            <th scope="col">Название</th>
            <th scope="col">Раунд</th>
            <th scope="col">Задание</th>
            <th scope="col">Ответ</th>
            <th scope="col">Действия</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let problem of problems">
            <td>{{problem.name}}</td>
            <td>{{problem.round_name}}</td>
            <td>
                <p>{{problem.description}}</p>
                <div *ngIf="problem.images">
                    <img *ngFor="let image_url of problem.images" src="{{image_url}}" class="problem-image" />
                </div>
                <p *ngIf="problem.options">
                    <span *ngFor="let option of problem.options; let i = index">{{i}}) {{option}} </span>
                </p>
            </td>
            <td></td>
            <td>
                <a href="/problem/{{problem.id}}/view">
                    <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;"><i class="bi bi-eye"></i></button>
                </a>
                <a href="/admin/logic/problem/{{problem.id}}/edit">
                    <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;"><i class="bi bi-pencil"></i></button>
                </a>
                <a href="/admin/logic/problem/{{problem.id}}/delete">
                    <button type="button" class="btn btn-sm btn-primary" style="margin: 1px;"><i class="bi bi-trash"></i></button>
                </a>
            </td>
        </tr>
    </tbody>
</table>
