import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './components/admin/admin.component'

import { AdminLogicComponent } from './components/admin/logic/logic.component'

import { AdminLogicGameEditComponent } from './components/admin/logic/admin-logic-game-edit/admin-logic-game-edit.component'
import { AdminLogicGameViewComponent } from './components/admin/logic/admin-logic-game-view/admin-logic-game-view.component'
import { AdminLogicProblemEditComponent } from './components/admin/logic/admin-logic-problem-edit/admin-logic-problem-edit.component'
import { AdminLogicProblemsComponent } from './components/admin/logic/admin-logic-problems/admin-logic-problems.component'

import { AdminLogicGamesComponent } from './components/admin/logic/admin-logic-games/admin-logic-games.component'
import { AdminLogicGameRoundViewComponent } from './components/admin/logic/admin-logic-game-round-view/admin-logic-game-round-view.component'

import { AdminLogicRoundsComponent } from './components/admin/logic/admin-logic-rounds/admin-logic-rounds.component'
import { AdminLogicRoundEditComponent } from './components/admin/logic/admin-logic-round-edit/admin-logic-round-edit.component'
import { AdminLogicGameProcessComponent } from './components/admin/logic/admin-logic-game-process/admin-logic-game-process.component'
import { AdminLogicGameAnswersComponent } from './components/admin/logic/admin-logic-game-answers/admin-logic-game-answers.component'

import { LogicComponent } from './components/logic/logic.component'
import { LogicGameStreamingComponent } from './components/logic/logic-game-streaming/logic-game-streaming.component'
import { LogicGameTeamComponent } from './components/logic/logic-game-team/logic-game-team.component'

import { LoginComponent } from './components/user/login/login.component'

import { LogicProblemViewComponent } from './components/logic/logic-problem-view/logic-problem-view.component';

import { PlayComponent } from './components/poker/play/play.component'
import { LogicMillionaireProblemComponent } from './components/logic/problem-types/logic-millionaire-problem/logic-millionaire-problem.component';

import { AdminSantaGameComponent } from './components/admin/santa/admin-santa-game/admin-santa-game.component';
import { AdminSantaGamesComponent } from './components/admin/santa/admin-santa-games/admin-santa-games.component';

import { AdminUsersIndexComponent } from './components/admin/users/admin-users-index/admin-users-index.component';
import { AdminUsersEditComponent } from './components/admin/users/admin-users-edit/admin-users-edit.component';

import { AdminGameStatisticsGamesComponent } from './components/admin/game-statistics/admin-game-statistics-games/admin-game-statistics-games.component';
import { AdminGameStatisticsPlayersComponent } from './components/admin/game-statistics/admin-game-statistics-players/admin-game-statistics-players.component';
import { AdminGameStatisticsPlayersEditComponent } from './components/admin/game-statistics/admin-game-statistics-players-edit/admin-game-statistics-players-edit.component';

import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
    {
        path: 'admin', component: AdminComponent, children: [
            { path: 'santa/games', component: AdminSantaGamesComponent },
            { path: 'santa/games/new', component: AdminSantaGameComponent },
            { path: 'santa/game/:id/edit', component: AdminSantaGameComponent },

            { path: 'users', component: AdminUsersIndexComponent },
            { path: 'users/new', component: AdminUsersEditComponent },

            { path: 'game-statistics', component: AdminGameStatisticsGamesComponent },
            { path: 'game-statistics/:game_id/players', component: AdminGameStatisticsPlayersComponent },
            { path: 'game-statistics/:game_id/players/new', component: AdminGameStatisticsPlayersEditComponent },
        ]
    },
    {
        path: 'admin/logic', component: AdminLogicComponent, children: [
            { path: 'rounds', component: AdminLogicRoundsComponent },
            { path: 'round/:id/edit', component: AdminLogicRoundEditComponent },
            { path: 'rounds/new', component: AdminLogicRoundEditComponent },

            { path: 'problems', component: AdminLogicProblemsComponent },
            { path: 'problem/:id/edit', component: AdminLogicProblemEditComponent },
            { path: 'problems/new', component: AdminLogicProblemEditComponent },

            { path: 'games', component: AdminLogicGamesComponent },
            { path: 'games/new', component: AdminLogicGameEditComponent },
            { path: 'game/:id/edit', component: AdminLogicGameEditComponent },
            { path: 'game/:id/view', component: AdminLogicGameViewComponent },
            { path: 'game/:game_id/round/:round_id', component: AdminLogicGameRoundViewComponent },

            { path: 'game/:game_id/process', component: AdminLogicGameProcessComponent },
            { path: 'game/:game_id/answers', component: AdminLogicGameAnswersComponent },
        ]
    },
    {
        path: '', component: LogicComponent, children: [
            { path: 'game/:game_id/streaming', component: LogicGameStreamingComponent },
            { path: 'game/:game_id/team/:team_id', component: LogicGameTeamComponent },
            { path: 'problem/:id/view', component: LogicProblemViewComponent },
        ]
    },
    { path: 'poker/play', component: PlayComponent },
    { path: 'login', component: LoginComponent },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
