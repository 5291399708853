<div *ngIf="!problem?.answer" class="main-question">
    <div *ngIf="problem.images?.[0] && !problem.answer" class="main-question-image">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>
    <div class="main-question-text" *ngIf="!problem.answer">
        <app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
    </div>
</div>

<div class="main-answer" *ngIf="problem?.answer">
    <div *ngIf="problem?.answer?.description" class="main-title">
        <app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.answer.description"></app-scaled-text>
    </div>
    <img
        *ngIf="problem.answer.image"
        width="500"
        src="{{ problem.answer.image }}"
    />
    <div *ngIf="problem.answer?.teams" class="variants-container">
        <app-rotated-card
            *ngFor="
                let item of problem.answer.teams;
                let i = index;
                trackBy: _trackBy
            "
            [variant]="{
                seqNumber: (i + 1).toString(),
                text: item?.name + ' - ' + item?.answer
            }"
            [isPlaceholder]="item === null"
        ></app-rotated-card>
    </div>
</div>