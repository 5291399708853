<app-game-header *ngIf="header"
    [roundName]="header.round_name"
    [roundNumber]="header.round_num"
    [totalQuestionsCount]="header.question_count"
    [questionNum]="header.question_num"
></app-game-header>
<!-- большая картинка без текста -->
<!-- короткий текст и под ним картинка appAnswer -->
<!-- картинка слева длинный текст справа common -->
<!-- несколько картинок одного размера без текста images -->
<!-- просто текст без картинки на весь экран -->
<!-- под всем этим могут быть буквы или варианты (max 4 или сверху что-то маленькое, нр текст) -->


<div *ngIf="viewType == 'shortText'" class="main-question main-question_shortText">
    <div class="main-question-column">
        <div class="main-question-text" *ngIf="problem.description && !problem.answer">
            <app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
        </div>
        <div *ngIf="problem.images?.[0]" class="main-question-image main-question-image__column">
            <img src="{{ problem.images?.[0] }}" alt="" />
        </div>
    </div>
</div>

<div *ngIf="viewType == 'images'" class="main-content">
    <div class="main-images">
        <div *ngFor="let image_url of problem.images" class="main-image-item">
            <img src="{{image_url}}" alt="">
        </div>
    </div>
</div>

<div *ngIf="viewType == 'common'" class="main-question">
    <div *ngIf="problem.images?.[0]" class="main-question-image main-question-image__row">
        <img src="{{ problem.images?.[0] }}" alt="" />
    </div>
    <div *ngIf="problem.video_url" class="main-question-image main-question-image__row">
        <video src="{{problem.video_url}}" id="video-player" width="800" height="600"> </video>
    </div>

    <audio *ngIf="problem.audio_url" src="{{problem.audio_url}}" id="video-player" width="1" height="1"> </audio>

    <div class="main-question-text" *ngIf="problem.description && !problem.answer">
        <app-scaled-text [hasPicture]="problem.images?.[0]" [text]="problem.description"></app-scaled-text>
    </div>

    <div *ngIf="problem.need_default_music_sign" class="music-problem">
        <div class="music-problem-image">
            <div class="music-problem-image__default">
                <div class="music-problem-image-sign"></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="viewType == 'musicSign'" class="music-problem">
    <div class="music-problem-image">
        <div class="music-problem-image__default">
            <div class="music-problem-image-sign"></div>
        </div>
    </div>
</div>

<div *ngIf="problem.options" class="variants-container">
    <app-variant
        *ngFor="let item of problem.options; let i = index"
        [variant]="{seqNumber: _INDEX_CHARS_MAP[i], text: item, isCorrect: problem.answer?.description == i}"
    ></app-variant>
</div>

<div class="letters-container" *ngIf="problem.placeholder">
    <div
        class="letter-cell"
        *ngFor="let letter of problem.placeholder; trackBy: _trackBy"
        [ngClass]="{'closed': letter === null, 'space': letter === ' '}"
    >
        <span>{{letter}}</span>
    </div>
</div>

<div *ngIf="problem?.teams" class="main-answer">
    <div class="variants-container">
        <app-rotated-card
            *ngFor="
                let item of problem.teams;
                let i = index;
                trackBy: _trackBy
            "
            [variant]="{
                seqNumber: (i + 1).toString(),
                text: item?.name + ' - ' + item?.answer
            }"
            [isPlaceholder]="item === null"
        ></app-rotated-card>
    </div>
</div>
